export enum ROUTES {
  ABOUT_US = "/about/about-us",
  ACCOUNT_DASHBOARD = "/my-account",
  ACCOUNT_INVOICES = "/my-account/financial",
  ACCOUNT_ORDERS = "/my-account/orders",
  PAY = "/my-account/financial/pay",
  CAREERS = "/careers",
  CAREERS_VACANCIES = "/careers/vacancies",
  CART = "/cart",
  CATEGORY_KITCHENS = "/category/kitchens",
  CHECKOUT_LOGIN = "/checkout-login",
  HOMEPAGE = "/",
  KITCHENS = "/kitchens",
  LANDING_MKMAPP = "/mkm-app",
  LOGIN = "/login",
  CREATE_ACCOUNT = "/login?signup=true",
  BLOG = "/blog",
  RESET_PASSWORD = "/reset-password",
  SET_PASSWORD = "/set-password",
  CATEGORY = "/category",
  CATEGORY_BATHROOMS = "/category/bathrooms",
  CATEGORY_NAILS_SCREWS_FIXINGS = "/category/nails-screws-fixings",
  CATEGORY_BUILDING_MATERIALS = "/category/building-materials",
  CATEGORY_PAINT_DECORATING = "/category/paint-decorating",
  CATEGORY_DOORS_WINDOWS_JOINERY = "/category/doors-windows-joinery",
  CATEGORY_PLUMBING_HEATING = "/category/plumbing-heating",
  CATEGORY_ELECTRICAL = "/category/electrical",
  CATEGORY_TILES = "/category/tiles",
  CATEGORY_TIMBER = "/category/timber",
  CATEGORY_LANDSCAPING = "/category/landscaping",
  CATEGORY_TOOLS_EQUIPMENT = "/category/tools-equipment",
  INVOICES = "/my-account/financial",
  CREATE_CASH_SUCCESS = "/create-account/success",
  CREATE_CREDIT_SUCCESS = "/apply-for-credit/success",
  SEARCH = "/search",
  THEMATIC = "/explore",
  BRANCHES = "/branches/",
}
